import React from "react"
import { motion } from "framer-motion"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Button from "../components/Button/Button"

import immaterialLogo from "../images/immaterial-logo.png"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <motion.div id={"not-found-container"}>
      <motion.div style={{ margin: "auto auto" }}>
        <img
          src={immaterialLogo}
          style={{ height: 400, width: 400 }}
          alt={"Immaterial Logo"}
        />
        <motion.h1 style={{ fontFamily: "space mono" }}>
          PAGE NOT FOUND
        </motion.h1>
        <Button text={"BACK TO HOME"} to={"/"} />
      </motion.div>
    </motion.div>
  </Layout>
)

export default NotFoundPage
